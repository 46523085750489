<template>
  <v-container fluid>
    <BaseBreadcrumb
      class="py-2"
      :title="page.title"
      :icon="mdiInboxOutline"
    ></BaseBreadcrumb>

    <v-data-table
      :loading="isTableLoading"
      loading-text="Cargando..."
      :server-items-length="itemsTotalCount"
      :options.sync="tableOptions"
      :headers="tableHeaders"
      item-key="id"
      :items="items"
      class="elevation-4"
      sort-by="itemDateTime"
      :sort-desc="true"
      disable-sort
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50]
      }"
    >
      <template v-slot:top class="pb-2">
        <v-row no-gutters class="pa-4">
          <v-btn
            :disabled="isTableLoading"
            outlined
            color="primary"
            @click="onClickFilteringOnlyMe"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiFilterVariant }}
            </v-icon>
            {{
              filteringOnlyMe ? "Mostrar todos estados" : "Listo para firmar"
            }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            :disabled="isTableLoading"
            outlined
            color="primary"
            @click="getItems()"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiRefresh }}
            </v-icon>
            Refrescar
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.itemDateTime`]="{ item }">
        {{ new Date(item.itemDateTime).toLocaleString() }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-icon small class="mr-1" :color="getComputedStatusColor(item.status)">
          {{ mdiCircle }}
        </v-icon>
        {{ getComputedStatusText(item.status) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="showItemDetail(item)">
              <v-icon>
                {{ mdiEyeOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Mostrar detalle</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.link">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="goToLinkDocument(item.link)"
            >
              <v-icon>
                {{ mdiDraw }}
              </v-icon>
            </v-btn>
          </template>
          <span>Iniciar firma</span>
        </v-tooltip>
      </template>

      <template v-if="getHistoricMyDocumentsError" v-slot:no-data>
        <v-btn outlined class="ma-4" color="primary" @click="getItems()">
          Reintentar
        </v-btn>
      </template>

      <template v-slot:footer.prepend>
        <v-row>
          <v-spacer> </v-spacer>
          <v-btn
            icon
            class="mr-4"
            @click="loadFirstsItems"
            :disabled="isTableLoading || tableOptions.page === 1"
          >
            <v-icon>
              {{ mdiChevronDoubleLeft }}
            </v-icon>
          </v-btn>

          <v-btn
            icon
            class="mr-8"
            @click="loadLastsItems"
            :disabled="
              isTableLoading ||
                tableOptions.page * tableOptions.itemsPerPage >= itemsTotalCount
            "
          >
            <v-icon>
              {{ mdiChevronDoubleRight }}
            </v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog scrollable v-model="dialogDetail" width="auto">
      <Loading
        :visible="documentSetDetails === null"
        :message="'Recuperando detalle...'"
      />

      <v-card v-if="documentSetDetails != null">
        <v-card-title class="text-h5">
          Ficha detalle
        </v-card-title>

        <v-card-text>
          <h4>Id del sobre</h4>
          <p>
            {{ documentSetDetails.documentSetId }}
          </p>
          <h4>Nombre del sobre</h4>
          <p>
            {{ documentSetDetails.documentSetName }}
          </p>

          <h4>Estado de la solicitud</h4>
          <p>
            <v-icon
              small
              class="mr-1 pb-1"
              :color="getComputedStatusColor(documentSetDetails.status)"
            >
              {{ mdiCircle }}
            </v-icon>
            <span>{{ getComputedStatusText(documentSetDetails.status) }}</span>
          </p>

          <h4>Nombre del remitente</h4>
          <p>
            {{ documentSetDetails.documentSetSenderName }}
          </p>

          <h4>Email del remitente</h4>
          <p>
            {{ documentSetDetails.documentSetSenderMail }}
          </p>

          <h4>Fecha de creación del sobre</h4>
          <p>
            {{
              new Date(
                documentSetDetails.documentSetCreationDate
              ).toLocaleString()
            }}
          </p>

          <h4>Fecha de expiración del sobre</h4>
          <p>
            {{
              new Date(
                documentSetDetails.documentSetExpirationDate
              ).toLocaleString()
            }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="updatingSeeDetail"
            @click="detailRefresh()"
            text
            color="primary"
          >
            Refrescar
          </v-btn>

          <v-spacer> </v-spacer>
          <v-btn @click="dialogDetail = false" text color="primary">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  mdiStateMachine,
  mdiInboxOutline,
  mdiRefresh,
  mdiDraw,
  mdiFilterVariant,
  mdiFilterVariantRemove,
  mdiCircle,
  mdiEyeOutline,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight
} from "@mdi/js";
import { GoToUrl } from "../../../utils/utils";
import Loading from "@/components/commons/Loading";

export default {
  name: "RequestsReceived",

  components: {
    Loading
  },

  data: () => ({
    page: {
      title: " Documentos Recibidos"
    },
    tableOptions: {},
    tableHeaders: [
      {
        text: "NOMBRE",
        value: "documentSetName"
      },
      { text: "ESTADO", value: "status" },
      {
        text: "FECHA",
        value: "itemDateTime"
      },
      {
        text: "ACCIONES",
        value: "actions"
      }
    ],
    mdiStateMachine: mdiStateMachine,
    mdiInboxOutline: mdiInboxOutline,
    mdiRefresh: mdiRefresh,
    mdiDraw: mdiDraw,
    mdiFilterVariant: mdiFilterVariant,
    mdiCircle: mdiCircle,
    mdiEyeOutline: mdiEyeOutline,
    mdiFilterVariantRemove: mdiFilterVariantRemove,
    mdiChevronDoubleLeft: mdiChevronDoubleLeft,
    mdiChevronDoubleRight: mdiChevronDoubleRight,

    selectedItem: null,
    updatingSeeDetail: false,
    updatingSeeEventsDetail: false,
    documentSetDetails: undefined,
    documentSetError: null,
    getHistoricMyDocumentsError: false,
    documentSetAuditTrail: undefined,

    dialogDetail: false,
    filteringOnlyMe: false,

    documentStatus: [
      { value: 0, text: "Desconocido" },
      { value: 100, text: "Creado" },
      { value: 200, text: "En proceso" },
      { value: 300, text: "Completado" },
      { value: 400, text: "Cancelado" },
      { value: 500, text: "Rechazado" },
      { value: 600, text: "Eliminado" },
      { value: 700, text: "Caducado" },
      { value: 800, text: "Erróneo" }
    ],

    recipientStatus: [
      { value: 0, text: "Desconocido" },
      { value: 1, text: "Creado" },
      { value: 2, text: "Pendiente" },
      { value: 3, text: "Firmando" },
      { value: 4, text: "Aceptado" },
      { value: 5, text: "Rechazado" },
      { value: 6, text: "Notificado" },
      { value: 7, text: "Firmado" },
      { value: 8, text: "Cancelado" }
    ],

    computedStatus: [
      { value: 100, text: "Pendiente" },
      { value: 200, text: "Listo para firmar" },
      { value: 300, text: "Firmado" },
      { value: 400, text: "Finalizado" }
    ],

    isTableLoading: false
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },

    tableOptions: {
      deep: true,
      handler() {
        this.getItems();
      }
    }
  },

  computed: {
    items() {
      return (
        this.$store.state.myDocuments &&
        this.$store.state.myDocuments.map(item => {
          if (!item.documentSetName)
            item.documentSetName = "Mi documento sin nombre";
          item.status = this.getStatusComputed(
            item.documentSetStatus,
            item.recipientActionStatus
          );
          return item;
        })
      );
    },

    itemsTotalCount() {
      return this.$store.getters.historicMyDocumentsItems;
    }
  },

  methods: {
    getStatusComputed(documentSetStatus, recipientActionStatus) {
      let status = 100;
      if (documentSetStatus === 0 || documentSetStatus === 100) status = 100;
      else if (documentSetStatus === 200) {
        if (recipientActionStatus === 0 || recipientActionStatus === 1)
          status = 100;
        else if (recipientActionStatus === 2 || recipientActionStatus === 3)
          status = 200;
        else if (recipientActionStatus === 4) status = 300;
        else status = 400;
      } else if (documentSetStatus === 300) status = 300;
      else status = 400;
      return status;
    },
    showItemDetail(item) {
      this.selectedItem = item;
      this.getDocumentDetails(void 0, true);
      this.dialogDetail = true;
    },
    getDocumentDetails(
      searchById = String(),
      showingError,
      isUpdate = false,
      onSuccess
    ) {
      if (!isUpdate) this.documentSetDetails = null;
      this.$store
        .dispatch(
          "getDetailMyDocumentItem",
          searchById ? searchById : this.selectedItem.id
        )
        .then(response => {
          if (response.status === 200) {
            if (isUpdate) {
              this.$toasted.global.toast_success({
                message: "Actualizado correctamente."
              });
            }
            response.data.result.status = this.getStatusComputed(
              response.data.result.documentSetStatus,
              response.data.result.recipientActionStatus
            );
            this.documentSetDetails = response.data.result;
            if (onSuccess) onSuccess();
            if (searchById) {
              this.$toasted.global.toast_success({
                message: "Se ha recuperado el sobre correctamente"
              });
              this.selectedItem = {
                documentSetName: response.data.documentSetName,
                id: response.data.documentSetId,
                itemDateTime: response.data.creationDate,
                status: response.data.status
              };
            }
          } else {
            this.processErrorDetails(showingError);
          }
        })
        .catch(err => {
          this.$toasted.global.toast_error({
            message: "No se han podido recuperar los detalles del sobre"
          });
          console.log(err);
          this.processErrorDetails(showingError);
        });
    },

    processErrorDetails(showingError) {
      if (showingError && this.selectedItem) {
        this.$toasted.global.toast_error({
          message:
            "No se han podido recuperar los detalles del sobre: " +
            this.selectedItem.documentSetName
        });
      }
      this.dialogDetail = false;
    },

    detailRefresh() {
      this.updatingSeeDetail = true;
      this.getDocumentDetails(void 0, true, true, () => {
        setTimeout(() => {
          this.updatingSeeDetail = false;
        }, 500);
      });
    },

    goToLinkDocument(link) {
      GoToUrl(link);
    },

    getComputedStatusText(status) {
      var statusFound = this.computedStatus.find(element => {
        return element.value == status;
      });
      return statusFound != undefined ? statusFound.text : "";
    },

    getComputedStatusColor(status) {
      switch (status) {
        case 100:
          return "#20a9ef";
        case 200:
          return "#20a9ef";
        case 300:
          return "#52bc03";
        case 400:
          return "#f5ad00";
        default:
          return "#20a9ef";
      }
    },

    loadFirstsItems() {
      if (this.tableOptions.page === 1) {
        this.getItems();
      } else {
        this.tableOptions.page = 1;
      }
    },

    loadLastsItems() {
      let page = Math.ceil(
        this.itemsTotalCount / this.tableOptions.itemsPerPage
      );
      if (this.tableOptions.page !== page) {
        this.tableOptions.page = page;
      } else {
        this.getItems();
      }
    },

    onClickFilteringOnlyMe() {
      this.filteringOnlyMe = !this.filteringOnlyMe;
      this.loadFirstsItems();
    },

    /** HISTORIC */

    getItems() {
      let offset =
        this.tableOptions.itemsPerPage * (this.tableOptions.page - 1);
      let limit = this.tableOptions.itemsPerPage;

      let itemFilteringDocumentStatus = this.filteringOnlyMe ? [200] : void 0;
      let itemFilteringRecipientStatus = this.filteringOnlyMe ? [2, 3] : void 0;

      this.isTableLoading = true;
      this.$store
        .dispatch("getMyDocuments", {
          itemFilteringDocumentStatus,
          itemFilteringRecipientStatus,
          limit,
          offset
        })
        .then(() => {
          this.getHistoricMyDocumentsError = false;
        })
        .catch(err => {
          console.log(err);
          this.getHistoricMyDocumentsError = true;
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    }
  }
};
</script>
<style scoped>
@media (max-width: 485px) {
  .btn--expanded {
    width: 95%;
  }
}
</style>
